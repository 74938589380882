import http from "@/utils/http/index.js";

export default {
  userPage: (param) => {
    return http.$p("/sys-user/page", param);
  },
  userDetail: (param) => {
    return http.$p("/sys-user/detail", param);
  },
  userAdd: (param) => {
    return http.$p("/sys-user/add", param);
  },
  userModify: (param) => {
    return http.$p("/sys-user/modify", param);
  },
  userModInfo: (param) => {
    return http.$p("/sys-user/mod-info", param);
  },
  userChgStat: (param) => {
    return http.$p("/sys-user/chg-stat", param);
  },
  userDelete: (param) => {
    return http.$p("/sys-user/delete", param);
  },
};
