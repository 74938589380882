import http from "@/utils/http/index.js";

export default {
  userPage: (param) => {
    return http.$p("/ar-user/page", param);
  },
  userDetail: (param) => {
    return http.$p("/ar-user/detail", param);
  },
  userAddCheckInExistsAgencyEr: (param) => {
    return http.$p("/ar-user/add-check-if-exists-in-agency-er", param);
  },
  userAdd: (param) => {
    return http.$p("/ar-user/add", param);
  },
  userModify: (param) => {
    return http.$p("/ar-user/modify", param);
  },
  userChgStat: (param) => {
    return http.$p("/ar-user/chg-stat", param);
  },
  userDelete: (param) => {
    return http.$p("/ar-user/delete", param);
  },

  roleListCurrCompany: () => {
    return http.$p("/role/list-curr-company");
  },
  roleListToBind: (param) => {
    return http.$p("/role/list-to-bind", param);
  },
};
