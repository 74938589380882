import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/mape/home/HomeView.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { ignoreAuth: true },
  },
  {
    path: "/error",
    name: "error",
    component: () => import("../views/mape/Error.vue"),
    meta: { ignoreAuth: true },
  },
  {
    path: "/contactUs",
    name: "ContactUs",
    component: () => import("../views/mape/ContactUs.vue"),
    meta: { ignoreAuth: true },
  },
  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ "../views/mape/LoginView.vue"),
    meta: { ignoreAuth: true },
  },
  {
    path: "/register",
    name: "register",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "register" */ "../views/mape/RegisterView.vue"),
    meta: { ignoreAuth: true },
  },
  {
    //PrivacyPolicy
    path: "/privacyPolicy",
    name: "privacyPolicy",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "register" */ "../views/mape/PrivacyPolicy.vue"),
    meta: { ignoreAuth: true },
  },
  {
    path: "/print",
    name: "platformPrint",
    component: () => import("../views/mape/PlatformPrint.vue"),
    children: [
      {
        path: "/print/businessInsights",
        name: "BusinessInsightsPrint",
        component: () => import("../views/mape/reports/business/BusinessInsightsPrint.vue"),
      },
      {
        path: "/print/businessInsightsSimeDarby",
        name: "BusinessInsightsSimeDarbyPrint",
        component: () => import("../views/mape/reports/businessSimeDarby/BusinessInsightsPrint.vue"),
      },
      {
        path: "/print/businessShampooV2",
        name: "BusinessShampooV2Print",
        component: () => import("../views/mape/reports/businessShampooV2/BusinessInsightsPrint.vue"),
      },
      {
        path: "/print/summarycontributions",
        name: "SummaryContributionsPrint",
        component: () => import("../views/mape/reports/overview/SummaryContributionsPrint.vue"),
      },
      {
        path: "/print/returnoninvestment",
        name: "ReturnOnInvestmentPrint",
        component: () => import("../views/mape/reports/overview/ReturnOnInvestmentPrint.vue"),
      },
      {
        path: "/print/profitandlossovertime",
        name: "ProfitAndLossOvertimePrint",
        component: () => import("../views/mape/reports/overview/ProfitAndLossOvertimePrint.vue"),
      },
      //print model
      {
        path: "/print/modelfitreport",
        name: "ModelFitAndStatisticsPrint",
        component: () => import("../views/mape/reports/model/ModelFitAndStatisticsPrint.vue"),
      },
      {
        path: "/print/decompositionanalysisreport",
        name: "DecompositionAnalysisPrint",
        component: () => import("../views/mape/reports/model/DecompositionAnalysisPrint.vue"),
      },
      {
        path: "/print/roiovertime",
        name: "ROIOverTimePrint",
        component: () => import("../views/mape/reports/model/ROIOverTimePrint.vue"),
      },
      {
        path: "/print/responsecurves",
        name: "ResponseCurvesPrint",
        component: () => import("../views/mape/reports/model/ResponseCurvesPrint.vue"),
      },

      //print monthly
      {
        path: "/print/validationofmodelfitandstatistics",
        name: "ValidationOfModelFitAndStatisticsPrint",
        component: () => import("../views/mape/reports/monthly/ValidationOfModelFitAndStatisticsPrint.vue"),
      },
      {
        path: "/print/recentdecompositionanalysis",
        name: "RecentDecompositionAnalysisPrint",
        component: () => import("../views/mape/reports/monthly/RecentDecompositionAnalysisPrint.vue"),
      },
      {
        path: "/print/recentsummarycontributions",
        name: "RecentSummaryContributionsPrint",
        component: () => import("../views/mape/reports/monthly/RecentSummaryContributionsPrint.vue"),
      },
      {
        path: "/print/postmodelroiovertime",
        name: "PostModelROIOverTimePrint",
        component: () => import("../views/mape/reports/monthly/PostModelROIOverTimePrint.vue"),
      },
      {
        path: "/print/postmodelprofitandlossovertime",
        name: "PostModelProfitAndLossOverTimePrint",
        component: () => import("../views/mape/reports/monthly/PostModelProfitAndLossOverTimePrint.vue"),
      },
    ],
  },
  {
    path: "/platform2",
    name: "platform2",
    component: () => import("../views/mape/Platform2.vue"),
    children: [
      // subscriptions
      {
        path: "/subscription",
        name: "subscription",
        component: () => import("../views/mape/landing/Subscription.vue"),
      },
      {
        path: "/subscription/project/:type",
        name: "subscriptionProject",
        component: () => import("../views/mape/landing/SubscriptionProject.vue"),
      },

      // Home Page
      {
        path: "/home-page",
        name: "platformHome",
        component: () => import("../views/mape/dashboard/PlatformHome.vue"),
      },

      // Personal Settings
      {
        path: "/personal",
        name: "personal",
        component: () => import("../views/mape/platformManagement/personal/Personal.vue"),
        children: [
          {
            path: "profile",
            name: "profile",
            component: () => import("../views/mape/platformManagement/personal/Profile.vue"),
          },
          {
            path: "settings",
            name: "settings",
            component: () => import("../views/mape/platformManagement/personal/Settings.vue"),
          },
          {
            path: "credentials",
            name: "dpCredentialMgmt",
            component: () => import("../views/mape/dp/dataSources/CredentialMgmt.vue"),
          },
          {
            path: "credentials/modify/:credentialId",
            name: "dpCredentialModify",
            component: () => import("../views/mape/dp/dataSources/CredentialModify.vue"),
          },
        ],
      },

      // Digital Performance
      {
        path: "/dp/data-source-mgmt",
        name: "dpDataSourceList",
        component: () => import("../views/mape/dp/dataSources/DataSourceList.vue"),
      },
      {
        path: "/dp/data-source-mgmt/config/:dataSourceId",
        name: "dpDataSourceConfig",
        component: () => import("../views/mape/dp/dataSources/DataSourceConfig.vue"),
      },
      {
        path: "/dp/data-source-mgmt/add",
        name: "dpDataSourceAdd",
        component: () => import("../views/mape/dp/dataSources/DataSourceAdd.vue"),
      },
      {
        path: "/dp/report",
        name: "dpReportList",
        component: () => import("../views/mape/dp/reports/ReportList.vue"),
      },
      {
        path: "/dp/report/view-instant-report",
        name: "dpInstantReportView",
        component: () => import("../views/mape/dp/reports/PerformanceReport.vue"),
      },
      {
        path: "/dp/report/instant-report-add",
        name: "dpInstantReportAdd",
        component: () => import("../views/mape/dp/reports/DataSourcesForInstantReport.vue"),
      },
      {
        path: "/dp/report/custom-report-add",
        name: "dpCustomReportAdd",
        component: () => import("../views/mape/dp/reports/DataSourcesForInstantReport.vue"),
      },
      {
        path: "/dp/report/data-source-add",
        name: "dpReportDataSourceAdd",
        component: () => import("../views/mape/dp/dataSources/DataSourceAdd.vue"),
      },
      {
        path: "/dp/export",
        name: "dpExport",
        component: () => import("../views/mape/dp/export/Export.vue"),
        children: [
          {
            path: "explorer",
            name: "dpExportExplorer",
            component: () => import("../views/mape/dp/export/Explorer.vue"),
          },
          {
            path: "automate",
            name: "dpExportAutomate",
            component: () => import("../views/mape/dp/export/Automate.vue"),
          },
        ],
      },
      {
        path: "/dp/customise-data",
        name: "dpCustomiseData",
        component: () => import("../views/mape/dp/customiseData/CustomiseData.vue"),
        children: [
          {
            path: "dimensions",
            name: "dpDimensions",
            component: () => import("../views/mape/dp/customiseData/Dimensions.vue"),
          },
          {
            path: "metrics",
            name: "dpMetrics",
            component: () => import("../views/mape/dp/customiseData/Metrics.vue"),
          },
        ],
      },

      // Marketing Mix
      {
        path: "/mm/business-insight",
        name: "mmBusiness",
        component: () => import("../views/mape/mm/BusinessInsight.vue"),
        children: [
          {
            path: "shampoo",
            name: "mmBusinessInsights",
            component: () => import("../views/mape/mm/business/BusinessInsights.vue"),
          },
          {
            path: "simeDarby",
            name: "mmBusinessInsightsSimeDarby",
            component: () => import("../views/mape/mm/businessSimeDarby/BusinessInsights.vue"),
          },
          {
            path: "shampooV2",
            name: "mmBusinessShampooV2",
            component: () => import("../views/mape/mm/businessShampooV2/BusinessInsights.vue"),
          },
        ],
      },

      {
        path: "/mm/overview",
        name: "mmOverview",
        component: () => import("../views/mape/mm/overview/Overview.vue"),
        children: [
          {
            path: "decompositionanalysisreport",
            name: "mmDecompositionanalysisreport",
            component: () => import("../views/mape/mm/overview/DecompositionAnalysisV2.vue"),
          },
          {
            path: "summary-contributions",
            name: "mmSummaryContributions",
            component: () => import("../views/mape/mm/overview/SummaryContributionsV2.vue"),
          },
          {
            path: "return-on-investment",
            name: "mmReturnOnInvestment",
            component: () => import("../views/mape/mm/overview/ReturnOnInvestmentV2.vue"),
          },
          {
            path: "roi-over-time",
            name: "mmRoiOverTime",
            component: () => import("../views/mape/mm/overview/ROIOverTimeV2.vue"),
          },
          {
            path: "profit-and-loss-overtime",
            name: "mmProfitAndLossOvertime",
            component: () => import("../views/mape/mm/overview/ProfitAndLossOvertimeV2.vue"),
          },
        ],
      },
      {
        path: "/mm/model-reports",
        name: "mmModelReports",
        component: () => import("../views/mape/mm/model/ModelReports.vue"),
        children: [
          {
            path: "modelFitStatistics",
            name: "mmModelFitStatistics",
            component: () => import("../views/mape/mm/model/ModelFitStatistics.vue"),
          },

          {
            path: "responsecurves",
            name: "mmResponsecurves",
            component: () => import("../views/mape/mm/model/ResponseCurvesV2.vue"),
          },
        ],
      },
      {
        path: "/mm/monitoring-reports",
        name: "mmMonitoringReports",
        component: () => import("../views/mape/mm/monitoring/MonitoringReports.vue"),
        children: [
          {
            path: "validationOfModelFitAndStatistics",
            name: "mmValidationOfModelFitAndStatistics",
            component: () => import("../views/mape/mm/monitoring/ValidationOfModelFitAndStatistics.vue"),
          },
          {
            path: "recentDecompositionAnalysis",
            name: "mmRecentDecompositionAnalysis",
            component: () => import("../views/mape/mm/monitoring/RecentDecompositionAnalysis.vue"),
          },
          {
            path: "recentSummaryContributions",
            name: "mmRecentSummaryContributions",
            component: () => import("../views/mape/mm/monitoring/RecentSummaryContributions.vue"),
          },
          {
            path: "postModelROIOverTime",
            name: "mmPostModelROIOverTime",
            component: () => import("../views/mape/mm/monitoring/PostModelROIOverTime.vue"),
          },
          {
            path: "postModelProfitAndLossOverTime",
            name: "mmPostModelProfitAndLossOverTime",
            component: () => import("../views/mape/mm/monitoring/PostModelProfitAndLossOverTime.vue"),
          },
        ],
      },
      {
        path: "/mm/data-management",
        name: "mmDataManagement",
        component: () => import("../views/mape/datamanagement/DataManagement.vue"),
      },

      // Platform Management
      {
        path: "/pm/company-mgmt",
        name: "companyMgmt",
        component: () => import("../views/mape/platformManagement/company/CompanyMgmt.vue"),
        children: [
          {
            path: "list",
            name: "companyMgmtList",
            component: () => import("../views/mape/platformManagement/company/CompanyList.vue"),
          },
          {
            path: "add",
            name: "companyMgmtAdd",
            component: () => import("../views/mape/platformManagement/company/CompanyEdit.vue"),
          },
          {
            path: "modify/:companyId",
            name: "companyMgmtModify",
            component: () => import("../views/mape/platformManagement/company/CompanyEdit.vue"),
          },
          {
            path: "assign-subsidiaries/:companyId",
            name: "companyMgmtAssignSubsidiaries",
            component: () => import("../views/mape/platformManagement/company/AssignSubsidiaries.vue"),
          },
          {
            path: "subsidiaries-assignment",
            name: "subsidiariesAssignment",
            component: () => import("../views/mape/platformManagement/company/AssignSubsidiaries.vue"),
          },
        ],
      },
      {
        path: "/pm/system-user-mgmt",
        name: "systemUserMgmt",
        component: () => import("../views/mape/platformManagement/systemUser/SystemUserMgmt.vue"),
        redirect: "/pm/system-user-mgmt/list", // 重定向到默认子路由
        children: [
          {
            path: "list",
            name: "systemUserList",
            component: () => import("../views/mape/platformManagement/systemUser/SystemUserList.vue"),
          },
          {
            path: "add",
            name: "systemUserAdd",
            component: () => import("../views/mape/platformManagement/systemUser/SystemUserEdit.vue"),
          },
          {
            path: "modify/:userId",
            name: "systemUserModify",
            component: () => import("../views/mape/platformManagement/systemUser/SystemUserEdit.vue"),
          },
        ],
      },
      {
        path: "/pm/user-mgmt",
        name: "userMgmt",
        component: () => import("../views/mape/platformManagement/user/UserMgmt.vue"),
        children: [
          {
            path: "list",
            name: "userList",
            component: () => import("../views/mape/platformManagement/user/UserList.vue"),
          },
          {
            path: "add",
            name: "userAdd",
            component: () => import("../views/mape/platformManagement/user/UserEdit.vue"),
          },
          {
            path: "modify/:arId",
            name: "userModify",
            component: () => import("../views/mape/platformManagement/user/UserEdit.vue"),
          },
          {
            path: "access-control",
            name: "accessControl",
            component: () => import("../views/mape/platformManagement/user/AccessControl.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/platform",
    name: "platform",
    component: () => import("../views/mape/Platform.vue"),
    children: [
      {
        path: "/businessInsights",
        name: "businessInsights",
        component: () => import("../views/mape/reports/business/BusinessInsights.vue"),
      },
      {
        path: "/businessInsightsSimeDarby",
        name: "businessInsightsSimeDarby",
        component: () => import("../views/mape/reports/businessSimeDarby/BusinessInsights.vue"),
      },
      {
        path: "/businessShampooV2",
        name: "businessShampooV2",
        component: () => import("../views/mape/reports/businessShampooV2/BusinessInsights.vue"),
      },
      //homePage
      {
        path: "/homePage",
        name: "homePage",
        component: () => import("../views/mape/dashboard/PlatformHome.vue"),
      },
      //overview
      {
        path: "/summarycontributionsreport",
        name: "summarycontributionsreport",
        component: () => import("../views/mape/reports/overview/SummaryContributions.vue"),
      },
      {
        path: "/returnoninvestmentreport",
        name: "returnoninvestmentreport",
        component: () => import("../views/mape/reports/overview/ReturnOnInvestment.vue"),
      },
      {
        path: "/profitandlossovertime",
        name: "profitandlossovertime",
        component: () => import("../views/mape/reports/overview/ProfitAndLossOvertime.vue"),
      },
      //model
      {
        path: "/modelfitreport",
        name: "modelfitreport",
        component: () => import("../views/mape/reports/model/ModelFitAndStatistics.vue"),
      },
      {
        path: "/decompositionanalysisreport",
        name: "decompositionanalysisreport",
        component: () => import("../views/mape/reports/model/DecompositionAnalysis.vue"),
      },
      {
        path: "/roiovertime",
        name: "roiovertime",
        component: () => import("../views/mape/reports/model/ROIOverTime.vue"),
      },
      {
        path: "/responsecurves",
        name: "responsecurves",
        component: () => import("../views/mape/reports/model/ResponseCurves.vue"),
      },
      //monthly
      {
        path: "/validationofmodelfitandstatistics",
        name: "validationofmodelfitandstatistics",
        component: () => import("../views/mape/reports/monthly/ValidationOfModelFitAndStatistics.vue"),
      },
      {
        path: "/recentdecompositionanalysis",
        name: "recentdecompositionanalysis",
        component: () => import("../views/mape/reports/monthly/RecentDecompositionAnalysis.vue"),
      },
      {
        path: "/recentsummarycontributions",
        name: "recentsummarycontributions",
        component: () => import("../views/mape/reports/monthly/RecentSummaryContributions.vue"),
      },
      {
        path: "/postmodelroiovertime",
        name: "postmodelroiovertime",
        component: () => import("../views/mape/reports/monthly/PostModelROIOverTime.vue"),
      },
      {
        path: "/postmodelprofitandlossovertime",
        name: "postmodelprofitandlossovertime",
        component: () => import("../views/mape/reports/monthly/PostModelProfitAndLossOverTime.vue"),
      },
      // Digital Performance

      {
        path: "/data/views",
        name: "views",
        component: () => import("../views/mape/data/Views.vue"),
      },
      // {
      //   path: "/data/connectors",
      //   name: "connectors",
      //   component: () => import("../views/mape/data/connect/Connectors.vue"),
      //   children: [
      //     {
      //       path: "addConnect",
      //       name: "addConnect",
      //       component: () => import("../views/mape/data/connect/AddConnect.vue"),
      //     },
      //     {
      //       path: "connectList",
      //       name: "connectList",
      //       component: () => import("../views/mape/data/connect/ConnectList.vue"),
      //     },
      //   ],
      // },
      // data management
      {
        path: "/data/management/upload",
        name: "dmUpload",
        component: () => import("../views/mape/datamanagement/Upload.vue"),
      },
      {
        path: "/data/management/dashboard",
        name: "dmDashboard",
        component: () => import("../views/mape/datamanagement/Dashboard.vue"),
      },
      {
        path: "/data/management/explore",
        name: "dmExplore",
        component: () => import("../views/mape/datamanagement/Explore.vue"),
      },
      {
        path: "/data/management/profile",
        name: "dmProfile",
        component: () => import("../views/mape/datamanagement/Profile.vue"),
      },
      {
        path: "/data/management/validation",
        name: "dmValidation",
        component: () => import("../views/mape/datamanagement/Validation2.vue"),
      },
    ],
  },
  {
    path: "/operation/login",
    name: "oprLogin",
    component: () => import("../views/mapeoperation/OprLoginView.vue"),
    meta: { ignoreAuth: true },
  },
  {
    path: "/operation/platform",
    name: "oprPlatform",
    component: () => import("../views/mapeoperation/OprPlatform.vue"),
    meta: {
      oprPer: true, // Operational Permission 运营权限
    },
    children: [
      {
        path: "/operation/sys-operator",
        name: "sysOperator",
        component: () => import("../views/mapeoperation/SysOperator.vue"),
      },
      //etl
      {
        path: "/operation/etl",
        name: "etl",
        component: () => import("../views/mapeoperation/ETL.vue"),
      },
      {
        path: "/operation/dataset-import",
        name: "dataSetImport",
        component: () => import("../views/mapeoperation/DataSetImport.vue"),
      },
      {
        path: "/operation/data/import",
        name: "import",
        component: () => import("../views/mapeoperation/Import.vue"),
      },
      //ImportReportSource.vue
      {
        path: "/operation/etl/import-reteport-source",
        name: "importReportSource",
        component: () => import("../views/mapeoperation/ImportReportSource.vue"),
      },
      //dp
      {
        path: "/operation/dp/credential",
        name: "credential",
        component: () => import("../views/mapeoperation/OprCredential.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    // return 期望滚动到哪个的位置
    if (to.hash) {
      document.querySelector(to.hash).scrollIntoView(true);
      // return {
      //   selector: to.hash,
      //   behavior: "smooth",
      // };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.oprPer)) {
    // 需要是operator才能访问的路由
    if (!isOperator()) {
      // 如果不是
      // 先记录当前访问路径
      store.dispatch("user/updateLastVisitFullPath", to.fullPath);
      // 跳转到operator登录页
      next("/operation/login");
      return;
    }
  }

  if (to.matched.some((record) => !record.meta.ignoreAuth)) {
    // 需要登录才能访问的路由
    if (!isLoggedIn()) {
      // 如果未登录
      // 先记录当前访问路径
      store.dispatch("user/updateLastVisitFullPath", to.fullPath);
      // 跳转到登录页
      next("/login");
      return;
    }

    // 初次登录，需要强制修改密码
    let user = store.state.user;
    if (user && user.info && user.info.needToModifyPassword) {
      if (to.path !== "/personal/settings") {
        next("/personal/settings");
        return;
      }
    }
  }

  // 放行
  next();
});

function isLoggedIn() {
  // 判断用户是否已登录的逻辑
  let user = store.state.user;
  return user && user.info && user.info.tokenValue;
}

function isOperator() {
  // 判断用户是否是Operator
  let user = store.state.user;
  return user && user.info && user.info.roles && user.info.roles.includes("operator");
}

export default router;
