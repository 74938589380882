const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const PHONE_REGEX = /^\d{2,4}(?:[-\s]?\d{2,})*$/;

export default Object.freeze({
  EMAIL_REGEX: EMAIL_REGEX,
  isEmail: (v) => {
    return EMAIL_REGEX.test(v);
  },

  PHONE_REGEX: PHONE_REGEX,
  isPhone: (v) => {
    return PHONE_REGEX.test(v);
  },
});
