import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import vuetify from "./plugins/vuetify";
import i18n from "./i18n/index.js";
import "./assets/css/common.css";
import "./assets/css/font.css";

import Enum from "./utils/enum";
import API from "./apis";

// import * as echarts from "echarts";

import Toast from "@/components/toast";
import Confirm from "@/components/confirm";
import Message from "@/components/message";
Vue.use(Toast);
Vue.use(Confirm);
Vue.use(Message);

Vue.use(Enum);
Vue.use(API);

Vue.config.productionTip = false;

import echartsfun from "./utils/fun/echarts.js";
import regexfun from "./utils/fun/regex.js";
import reportfun from "./utils/fun/report.js";
Vue.prototype.$fun = {
  echarts: echartsfun,
  regex: regexfun,
  report: reportfun,
};

import constant from "./utils/constant/constant.js";
Vue.prototype.$constant = constant;
// Vue.prototype.$echarts = echarts;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
